<template>
  <div>
    <div>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group label="Deeplink:" label-size="lg">
          <b-form-input v-model="serviceData.deeplink" type="url"
            placeholder="Введите deeplink для баннера"></b-form-input>
        </b-form-group>
        <b-form-group label="Приоритет:" label-size="lg">
          <b-form-input v-model.number="serviceData.priority" type="number"
            placeholder="Введите приоритет для баннера"></b-form-input>
        </b-form-group>
        <b-form-group label="Изображение баннера:" label-size="lg">
          <b-form-file
              v-model="serviceData.bannerImage"
              :state="Boolean(serviceData.bannerImage)"
              accept="image/png, image/jpg, image/jpeg"
              placeholder="Загрузите картинку для баннера"
              drop-placeholder="Переместите картинку сюда...">
          </b-form-file>
        </b-form-group>
        <b-button variant="primary" type="submit">
          <div v-if="submitting">
            <b-spinner small></b-spinner>
            Создаётся
          </div>
          <div v-else>Создать</div>
        </b-button>
      </b-form>
    </div>
    <div>
      <b-alert v-model="isSubmitted" dismissible
               :variant="this.isError ? 'danger' : 'success'">
        <h6 class="alert-heading" v-if="isError">При создании баннера произошла ошибка!</h6>
        <h6 class="alert-heading" v-else>Создание баннера прошло успешно!</h6>
      </b-alert>
    </div>
  </div>
</template>

<script>
import bannerApi from "@/modules/banner";

export default {
  name: 'CreateBanner',
  data() {
    return {
      serviceData: {
        deeplink: '',
        priority: 0,
        bannerImage: null,
      },
      submitting: false,
      isSubmitted: false,
      isError: false
    }
  },
  methods: {
    onSubmit() {
      if (this.serviceData.bannerImage == null) {
        return;
      }

      this.isSubmitted = false;
      this.submitting = true;

      return bannerApi.createBanner(this.serviceData).then(() => {
        this.isError = false;
        this.isSubmitted = true;
      }).catch((e) => {
        this.isError = true;
        this.$bvToast.toast(e.response.status === 400 ? e.response.data.message : 'Возникла неизвестная ошибка', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true
        });
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
</script>
